.stories-container {
    padding-top: 15rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.story-container {
    margin-top: 10rem;
    width: 80%;
    height: 80%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.story-img {
    width: 45%;
    padding: 3rem;  
}

.story-images {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.story-text {
    color: #BCBCBC;
    display: flex;
    margin-left:9rem;
}


.story-text p {
    /* width: 55rem; */
    width: 70%;
    padding-left: 0.5rem;
    font-size: 1.8rem;
    padding-top: 0.5rem;
    text-align:left;
}

.story-text h1 {
    font-size: 5.5rem;
    
}


.quotes {
    color: #FABB51;
}

.img3 {
    width: 30%;
}
.color-white {
    color: white;
}

.img15 {
    width: 90%;
}

.story-images-1 {
    display:flex;
    align-items: center;
    justify-content:flex-start;
    padding-left: 6rem;
}
.text-after-images {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding-top: 3rem;
    

}

.story-images-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.imgFull  {
    width: 100%;
}


@media only screen and (max-width: 900px) {
    .story-images {
        flex-direction: column;
    }
    .story-text p {
     
     font-size: 1.2rem;
    }
    .story-text h1 {
   
        font-size: 4rem;
       }
    .story-text {
        
        margin-left: 3rem;
    }   
    .story-img {
        width: 100%;
    }
   
}

@media only screen and (max-width: 640px) {
    .story-images {
        flex-direction: column;
    }
    .story-text p {
        width: 70%;
        font-size: 1rem;
        padding-top:0.2rem;
    }
    .story-text h1 {
        font-size: 2.8rem;
    }
    .text-after-images {
        font-size: 1.2rem;
        font-weight: 100;
    }
   
    

}
@media only screen and (max-width: 535px) {
    .mobile-padding {
        padding-top:0.7rem;
        font-size: 3.5rem;
    }
    
}

@media only screen and (max-width: 420px) {
    .story-text {
        flex-direction: column;
        
    }
    .story-text p {
         width: 25rem;
    }
}

@media only screen and (max-width: 360px) {
    .story-text p {
        width: 18rem;
    }
}

@media only screen and (min-width: 1900px) {
    .story-text {
        padding-left: 4rem;
    }
    .story-text h1 {
        font-size: 9rem;
    }
   .story-text p{
    font-size: 3rem;
   }
    
}