.film-container {
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding-top: 15rem;
    margin-bottom: 10rem;
}

.video1 {
   margin-bottom: 20rem;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 90%;
    
}

.video-container {
    width: 100rem;
}

.film-container h1 {
    margin-bottom: 10rem;
}
.film-paragraph {
    color: white;
    font-size: 1.5rem;
    width: 80%;
    text-align: center;
    margin-bottom: 5rem;
}
