.background-container-contact {
    width: 100%;
    height: auto;
    padding-top: 15rem;
    background-color: inherit;
    display: flex;  
    align-items: center;
    flex-direction: column;
    background-color: #0f0e11;
   
}

.form-image-container {
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-form2 {
    display: flex;
    flex-direction: column;
    width: 60rem;
}
textarea {
    background-color: transparent;
    border: 1px solid #DDDDDD;
    color: white;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    padding: 0.8rem;
}
label {
    color: white;
    font-size: 1.5rem;
    padding: 0.3rem;
}

.form-item { 
    /* height: 4rem; */
    margin-bottom: 1rem;
    background-color: transparent;
    border: 1px solid #DDDDDD;
    color: white;
    font-size: 1.6rem;
    padding: 0.8rem;
}

.bar-info-container {
    
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
   
    background-color: #0f2f1b;
    
    color: #EEEEEE;
    width: 100%;
   
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
}
.btn {
    align-self: flex-start;
    padding: 0.7rem 2rem;
    color: white;
    background-color: transparent;
    border: 1px solid #DDDDDD;
    font-size: 1.5rem;
    border-radius: 0;
    margin-top: 1rem;
}

.btn:hover {
    color: white;
    background-color: #0f2f1b;
    border: 1px solid #0f2f1b;
    
}
.email-img {
   display: flex;
   justify-content: center;
   align-items: center;
    /* margin: 10rem; */
    width: 80%;
    max-width: 1000px;
    
}

.email-img img {
    width: 100%;
    margin-top: 7rem;
}
@media only screen and (max-width: 1200px) {
    .form-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-image-container img {
        width: 50rem;
    }
    .contact-form2 {
        width: 50rem;
        margin-bottom: 5rem;
    }
}

@media only screen and (max-width: 650px) {
    .form-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-image-container img {
        width: 35rem;
    }
    .contact-form2 {
        width: 35rem;
        padding-top: 5rem;
        
    }
    .bar-info-container {
      display: flex;
      flex-direction: column;
      margin-top:5rem;
      text-align: center;
      align-items:center;
    
    }
}
@media only screen and (max-width: 350px) {
    .form-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-image-container img {
        width: 25rem;
    }
    .contact-form2 {
        width: 25rem;
        padding-top: 5rem;
    }
}

