@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200&family=Lato:wght@100&family=Chonburi&family=Cormorant+SC:wght@300&family=Montserrat:wght@200;400;600&display=swap');
body {
  
  
  font-family: 'Montserrat';
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

    #0f3b1f
} */
