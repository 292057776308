.header-container {
  position: relative;
  text-align: center;
}

.main-img {
    width:100%; 
}

.main-title {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 8rem;
  font-weight: 800;
  color: #FABB51;  
  animation: fadeIn 3s; 
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @media screen and (max-width: 1280px) {
       .main-img {
        display: flex;
        justify-content: center;
        align-items: center;

       }
       .main-title {
        position:fixed;
        top: 50%;
        right: 20%;
       }
  } 

 @media screen and (max-width: 540px) {
    
    .main-title {
        font-size: 3.5rem;
        position:fixed;
        top: 40%;
        right: 25%;
    }
    .main-img {
      object-position:60%;
      object-fit: cover;
      height: 100vh;
    }
    
}
@media screen and (max-width: 920px) {
    .main-title {
           font-size: 6.5rem;
           width: 90%;
           position:fixed;
           top: 70%;
           right: 20%;
        }
        .main-img {
          object-position:60%;
          object-fit: cover;
          height: 100vh;
        }

   
}
   