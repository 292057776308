* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

html {
  font-size: 62.5%;

}

body {
  background-color: black;
  background-color:  #0f0e11;
  /* background-color:#0f2f1b;
  background-color: #1a1e20; */
  /* background-color: #111016;
  background-color: #323441; */
}

