.navbar {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    overflow: hidden;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: rgba(15, 14, 17, 0.8);
   
 }
    
.desktop-navlinks {

    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding-right: 5rem;
    padding-top: 1rem;
    
}

.mobile-navlinks {
    position: fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none; 
    z-index: 9000;
    width: 100%;
   
    background-color: #0f0e11;
    padding: 3rem;
   
   
}
.mobile-navlinks > li {
    padding-top: 3rem;
    font-size: 1.8rem;
}
.mobile-navlinks a {
    color: white;
}


.desktop-navlinks > li {
    padding-left: 3.5rem;
    font-size: 1.7rem;
    font-weight: 200; 
}

.desktop-navlinks a {
    color: white;
    
}

.desktop-navlinks a:hover {
    color: #FABB51;
    transition: color 0.3s ease-in-out;
}

.hamburger-icon {
    width: 3rem;
    padding-right: 1rem; 
}

.logo {
    font-size: 1.7rem;
    padding-left: 5rem;
}

.insta-logo {
    padding-left: 2rem;
}

@media only screen and (max-width: 920px) {
    .desktop-navlinks > li {
        font-size: 1.3rem;
        padding-left: 1.5rem

    }
    .logo {
        font-size: 1.3rem;
     
    }
}

@media only screen and (min-width: 720px) {
    .hamburger {
        display: none;
    }
}

@media only screen and (max-width: 720px) {
 .desktop-navlinks {
    
    display: none;
 }
 .hamburger {
    display: block;
    padding-right: 1rem;
    z-index: 10000;
   
 }
 .logo {
    padding-left: 2rem;
 }


}