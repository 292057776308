.menu-container {
    width: 100%;
    display: flex;
    background-color:#0f2f1b;
    align-items: center;
    flex-direction: column;
    padding-top: 15rem;
    padding-bottom: 10rem;
}

   

.menu-title {
    color: #FABB51;
    font-size: 5rem;
    font-weight:200;
}

.menu-title:after,
.menu-title:before{
   
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
    text-decoration:line-through solid 1px;

   
}


.menu-menu-container {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8rem;
}
.menu {
    width: 35%;
    padding: 4rem;
}
.btn-dwnld {
   
    padding: 0.5rem 1rem;
    color: white;
    background-color: transparent;
    border: 1px solid grey;
    font-size: 1.5rem;
    border-radius: 0;
    margin-top: 5rem;
}

.btn-dwnld:hover {
    color: white;
    background-color: #E11F27;
    border: 1px solid #E11F27;
    
}

@media only screen and (max-width: 920px) {
    .menu-menu-container {
        flex-direction: column;
    }
    .menu {
        width: 90%;
    }
    .menu-title {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 320px) {
    .menu-title {
        font-size: 2rem;
    }
}

