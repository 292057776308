@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200&family=Lato:wght@100&family=Chonburi&family=Cormorant+SC:wght@300&family=Montserrat:wght@200;400;600&display=swap);
body {
  
  
  font-family: 'Montserrat';
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

    #0f3b1f
} */

.header-container {
  position: relative;
  text-align: center;
}

.main-img {
    width:100%; 
}

.main-title {
  position: absolute;
  top: 65%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 8rem;
  font-weight: 800;
  color: #FABB51;  
  -webkit-animation: fadeIn 3s;  
          animation: fadeIn 3s; 
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  @media screen and (max-width: 1280px) {
       .main-img {
        display: flex;
        justify-content: center;
        align-items: center;

       }
       .main-title {
        position:fixed;
        top: 50%;
        right: 20%;
       }
  } 

 @media screen and (max-width: 540px) {
    
    .main-title {
        font-size: 3.5rem;
        position:fixed;
        top: 40%;
        right: 25%;
    }
    .main-img {
      object-position:60%;
      object-fit: cover;
      height: 100vh;
    }
    
}
@media screen and (max-width: 920px) {
    .main-title {
           font-size: 6.5rem;
           width: 90%;
           position:fixed;
           top: 70%;
           right: 20%;
        }
        .main-img {
          object-position:60%;
          object-fit: cover;
          height: 100vh;
        }

   
}
   
.film-container {
    width: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding-top: 15rem;
    margin-bottom: 10rem;
}

.video1 {
   margin-bottom: 20rem;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 90%;
    
}

.video-container {
    width: 100rem;
}

.film-container h1 {
    margin-bottom: 10rem;
}
.film-paragraph {
    color: white;
    font-size: 1.5rem;
    width: 80%;
    text-align: center;
    margin-bottom: 5rem;
}

.stories-container {
    padding-top: 15rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.story-container {
    margin-top: 10rem;
    width: 80%;
    height: 80%;
    display:flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.story-img {
    width: 45%;
    padding: 3rem;  
}

.story-images {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.story-text {
    color: #BCBCBC;
    display: flex;
    margin-left:9rem;
}


.story-text p {
    /* width: 55rem; */
    width: 70%;
    padding-left: 0.5rem;
    font-size: 1.8rem;
    padding-top: 0.5rem;
    text-align:left;
}

.story-text h1 {
    font-size: 5.5rem;
    
}


.quotes {
    color: #FABB51;
}

.img3 {
    width: 30%;
}
.color-white {
    color: white;
}

.img15 {
    width: 90%;
}

.story-images-1 {
    display:flex;
    align-items: center;
    justify-content:flex-start;
    padding-left: 6rem;
}
.text-after-images {
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.8rem;
    padding-top: 3rem;
    

}

.story-images-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.imgFull  {
    width: 100%;
}


@media only screen and (max-width: 900px) {
    .story-images {
        flex-direction: column;
    }
    .story-text p {
     
     font-size: 1.2rem;
    }
    .story-text h1 {
   
        font-size: 4rem;
       }
    .story-text {
        
        margin-left: 3rem;
    }   
    .story-img {
        width: 100%;
    }
   
}

@media only screen and (max-width: 640px) {
    .story-images {
        flex-direction: column;
    }
    .story-text p {
        width: 70%;
        font-size: 1rem;
        padding-top:0.2rem;
    }
    .story-text h1 {
        font-size: 2.8rem;
    }
    .text-after-images {
        font-size: 1.2rem;
        font-weight: 100;
    }
   
    

}
@media only screen and (max-width: 535px) {
    .mobile-padding {
        padding-top:0.7rem;
        font-size: 3.5rem;
    }
    
}

@media only screen and (max-width: 420px) {
    .story-text {
        flex-direction: column;
        
    }
    .story-text p {
         width: 25rem;
    }
}

@media only screen and (max-width: 360px) {
    .story-text p {
        width: 18rem;
    }
}

@media only screen and (min-width: 1900px) {
    .story-text {
        padding-left: 4rem;
    }
    .story-text h1 {
        font-size: 9rem;
    }
   .story-text p{
    font-size: 3rem;
   }
    
}
.menu-container {
    width: 100%;
    display: flex;
    background-color:#0f2f1b;
    align-items: center;
    flex-direction: column;
    padding-top: 15rem;
    padding-bottom: 10rem;
}

   

.menu-title {
    color: #FABB51;
    font-size: 5rem;
    font-weight:200;
}

.menu-title:after,
.menu-title:before{
   
    content:"\A0\A0\A0\A0\A0\A0\A0\A0";
    -webkit-text-decoration:line-through solid 1px;
            text-decoration:line-through solid 1px;

   
}


.menu-menu-container {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 8rem;
}
.menu {
    width: 35%;
    padding: 4rem;
}
.btn-dwnld {
   
    padding: 0.5rem 1rem;
    color: white;
    background-color: transparent;
    border: 1px solid grey;
    font-size: 1.5rem;
    border-radius: 0;
    margin-top: 5rem;
}

.btn-dwnld:hover {
    color: white;
    background-color: #E11F27;
    border: 1px solid #E11F27;
    
}

@media only screen and (max-width: 920px) {
    .menu-menu-container {
        flex-direction: column;
    }
    .menu {
        width: 90%;
    }
    .menu-title {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 320px) {
    .menu-title {
        font-size: 2rem;
    }
}


.background-container-contact {
    width: 100%;
    height: auto;
    padding-top: 15rem;
    background-color: inherit;
    display: flex;  
    align-items: center;
    flex-direction: column;
    background-color: #0f0e11;
   
}

.form-image-container {
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-form2 {
    display: flex;
    flex-direction: column;
    width: 60rem;
}
textarea {
    background-color: transparent;
    border: 1px solid #DDDDDD;
    color: white;
    font-size: 1.6rem;
    margin-bottom: 1rem;
    padding: 0.8rem;
}
label {
    color: white;
    font-size: 1.5rem;
    padding: 0.3rem;
}

.form-item { 
    /* height: 4rem; */
    margin-bottom: 1rem;
    background-color: transparent;
    border: 1px solid #DDDDDD;
    color: white;
    font-size: 1.6rem;
    padding: 0.8rem;
}

.bar-info-container {
    
    padding-top: 2rem;
    padding-bottom: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
   
    background-color: #0f2f1b;
    
    color: #EEEEEE;
    width: 100%;
   
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 400;
}
.btn {
    align-self: flex-start;
    padding: 0.7rem 2rem;
    color: white;
    background-color: transparent;
    border: 1px solid #DDDDDD;
    font-size: 1.5rem;
    border-radius: 0;
    margin-top: 1rem;
}

.btn:hover {
    color: white;
    background-color: #0f2f1b;
    border: 1px solid #0f2f1b;
    
}
.email-img {
   display: flex;
   justify-content: center;
   align-items: center;
    /* margin: 10rem; */
    width: 80%;
    max-width: 1000px;
    
}

.email-img img {
    width: 100%;
    margin-top: 7rem;
}
@media only screen and (max-width: 1200px) {
    .form-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-image-container img {
        width: 50rem;
    }
    .contact-form2 {
        width: 50rem;
        margin-bottom: 5rem;
    }
}

@media only screen and (max-width: 650px) {
    .form-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-image-container img {
        width: 35rem;
    }
    .contact-form2 {
        width: 35rem;
        padding-top: 5rem;
        
    }
    .bar-info-container {
      display: flex;
      flex-direction: column;
      margin-top:5rem;
      text-align: center;
      align-items:center;
    
    }
}
@media only screen and (max-width: 350px) {
    .form-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .form-image-container img {
        width: 25rem;
    }
    .contact-form2 {
        width: 25rem;
        padding-top: 5rem;
    }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

html {
  font-size: 62.5%;

}

body {
  background-color: black;
  background-color:  #0f0e11;
  /* background-color:#0f2f1b;
  background-color: #1a1e20; */
  /* background-color: #111016;
  background-color: #323441; */
}


.navbar {
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 8rem;
    overflow: hidden;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: rgba(15, 14, 17, 0.8);
   
 }
    
.desktop-navlinks {

    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding-right: 5rem;
    padding-top: 1rem;
    
}

.mobile-navlinks {
    position: fixed;
    top:0;
    left:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none; 
    z-index: 9000;
    width: 100%;
   
    background-color: #0f0e11;
    padding: 3rem;
   
   
}
.mobile-navlinks > li {
    padding-top: 3rem;
    font-size: 1.8rem;
}
.mobile-navlinks a {
    color: white;
}


.desktop-navlinks > li {
    padding-left: 3.5rem;
    font-size: 1.7rem;
    font-weight: 200; 
}

.desktop-navlinks a {
    color: white;
    
}

.desktop-navlinks a:hover {
    color: #FABB51;
    transition: color 0.3s ease-in-out;
}

.hamburger-icon {
    width: 3rem;
    padding-right: 1rem; 
}

.logo {
    font-size: 1.7rem;
    padding-left: 5rem;
}

.insta-logo {
    padding-left: 2rem;
}

@media only screen and (max-width: 920px) {
    .desktop-navlinks > li {
        font-size: 1.3rem;
        padding-left: 1.5rem

    }
    .logo {
        font-size: 1.3rem;
     
    }
}

@media only screen and (min-width: 720px) {
    .hamburger {
        display: none;
    }
}

@media only screen and (max-width: 720px) {
 .desktop-navlinks {
    
    display: none;
 }
 .hamburger {
    display: block;
    padding-right: 1rem;
    z-index: 10000;
   
 }
 .logo {
    padding-left: 2rem;
 }


}
